<template>
  <div class="readme-article">
    <h2 id="用户">4.用户</h2>
    <h3 id="用户列表">4.1用户列表</h3>
    <p>
      查看系统用户列表，包含用户头像、昵称、通过名片人，添加时间等信息；会员及余额暂无效果，待后续功能扩展。
    </p>
    <p>
      <img src="@/assets/img/card/4-1.png" />
    </p>
    <h3 id="用户等级">4.2用户等级</h3>
    <h4 id="等级管理">4.2.1等级管理</h4>
    <p>
      暂无作用，待后续功能扩展使用。
    </p>
    <!-- <p>
      <img src="@/assets/img/card/3-2.png" />
    </p> -->
    <h3 id="余额记录">4.3余额记录</h3>
    <h4 id="充值记录">4.3.1充值记录</h4>
    <p>
      暂无作用，待后续功能扩展使用。
    </p>
    <h4 id="余额明细">4.3.2余额明细</h4>
    <p>
      暂无作用，待后续功能扩展使用。
    </p>
    <h4 id="提现列表">4.3.3提现列表</h4>
    <p>
      暂无作用，待后续功能扩展使用。
    </p>
    <!-- <p>
      <img src="@/assets/img/card/3-2.png" />
    </p> -->
    <h3 id="客户需求">4.4客户需求</h3>
    <p>
      小程序中客户提交的表单信息列表，可查看客户提交的表单信息。
    </p>
    <p>
      <img src="@/assets/img/card/4-2.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Card4-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>